// StaticForm.tsx
import React, { useState } from 'react';
import { Box, Button, MenuItem, Select, FormControl, InputLabel, TextField, Grid } from '@mui/material'; // Using Material UI for styling

const CustomFormPage: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [textValue, setTextValue] = useState<string>('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    alert(`Selected value: ${selectedValue}, Text input: ${textValue}`); // Replace with actual handling logic
  };

  return (
    <Box component="form" onSubmit={handleSubmit} p={2} sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="dropdown-label">Select Option</InputLabel>
            <Select
              labelId="dropdown-label"
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value as string)}
              label="Select Option"
            >
              <MenuItem value="Option1">Option 1</MenuItem>
              <MenuItem value="Option2">Option 2</MenuItem>
              <MenuItem value="Option3">Option 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Text Input"
            variant="outlined"
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Submit
      </Button>
    </Box>
  );
};

export default CustomFormPage;
