import { Create, SimpleForm, TextInput, NumberInput, SelectInput, CreateProps } from 'react-admin';
import { JSX } from 'react/jsx-runtime';
import { Box } from '@mui/material'; // Using Material UI for styling
import SectionTitle from '../layout/component/sectionTitle';

const InventoryCreate = (props: JSX.IntrinsicAttributes & CreateProps<any, Error, any>) => (
  <Create {...props}>
    <SimpleForm>
      <SectionTitle title="Inventory" />
      <Box display="flex" flexDirection="row" gap="1rem" mb="1rem">
        <Box flex={1}>
          <TextInput source="inventory.org.id" label="Organisation" fullWidth />
        </Box>
        <Box flex={1}>
          <TextInput source="inventory.product.id" label="Product" fullWidth />
        </Box>
        <Box flex={1}>
          <TextInput source="inventory.source.store" label="Store" fullWidth />
        </Box>
        <Box flex={1}>
          <NumberInput source="inventory.inventory.position" label="Stock Position" fullWidth />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

export default InventoryCreate;
