import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, SelectInput, CreateProps } from 'react-admin';
import { JSX } from 'react/jsx-runtime';

const AlertCreate = (props: JSX.IntrinsicAttributes & CreateProps<any, Error, any>) => (
  <Create {...props}>
    <SimpleForm>
      {/* First Section: Item or Group */}
      <SelectInput source="item.type" label="Item Type" choices={[
        { id: '1', name: 'Type 1' },
        { id: '2', name: 'Type 2' },
      ]} />
      <TextInput source="item.id" label="Item ID" />
      <NumberInput source="item.threshold" label="Threshold" />

      {/* Second Section: Notification */}
      <SelectInput source="notification.type" label="Notification Type" choices={[
        { id: 'email', name: 'Email' },
        { id: 'sms', name: 'SMS' },
      ]} />
      <TextInput source="notification.recipients[0].recipient" label="Recipient 1" />
      <TextInput source="notification.recipients[1].recipient" label="Recipient 2" />
      {/* More recipient fields as needed */}

      {/* Third Section: Schedule */}
      <SelectInput source="frequency.type" label="Frequency Type" choices={[
        { id: 'daily', name: 'Daily' },
        { id: 'weekly', name: 'Weekly' },
      ]} />
      <SelectInput source="frequency.frequency" label="Frequency" choices={[
        { id: 'once', name: 'Once' },
        { id: 'twice', name: 'Twice' },
      ]} />
    </SimpleForm>
  </Create>
);

export default AlertCreate;
