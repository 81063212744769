import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, NumberInput, useDataProvider, EditProps } from 'react-admin';
import { Box } from '@mui/material'; // Using Material UI for styling
import { JSX } from 'react/jsx-runtime';


import SectionTitle from '../layout/component/sectionTitle'; // Import your custom Create component


// Define the type for the options
interface Option {
  id: string | number;
  name: string;
}


// Custom Edit Page
const CustomEditPage = (props: JSX.IntrinsicAttributes & EditProps<any, Error>) => {
  const dataProvider = useDataProvider();

  // Example of fetching values from a data provider
  // Replace 'resourceName' with the actual resource name
  const [dataProviderOptions, setDataProviderOptions] = React.useState<Option[]>([]);

  React.useEffect(() => {
    dataProvider.getList('notifiers', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' }
    }).then(response => {
      setDataProviderOptions(response.data.map(item => ({ id: item.id, name: item.name })));
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [dataProvider]);

  // Static data for dropdowns
  const staticDropdownOptions = [
    { id: '1', name: 'Product' },
    { id: '2', name: 'Category' },
    { id: '3', name: 'Catalog' }
  ];

  // Static data for dropdowns
  const staticFrequencyTypeOptions = [
    { id: 'daily', name: 'Daily' },
    { id: 'weekly', name: 'Weekly' }
  ];

  const staticFrequencyFrequencyOptions = [
    { id: 'once', name: 'Once' },
    { id: 'twice', name: 'Twice' }
  ];

  return (
    <Edit resource="inventories" {...props}>
      <SimpleForm>
        <SectionTitle title="Inventory" />
        <Box display="flex" flexDirection="row" gap="1rem" mb="1rem">
          <Box flex={1}>
            <TextInput source="id" disabled />
          </Box>
          <Box flex={1}>
            <TextInput source="inventory.org.id" label="Organisation" fullWidth />
          </Box>
          <Box flex={1}>
            <TextInput source="inventory.product.id" label="Product" fullWidth />
          </Box>
          <Box flex={1}>
            <TextInput source="inventory.source.store" label="Store" fullWidth />
          </Box>
          <Box flex={1}>
            <NumberInput source="inventory.inventory.position" label="Stock Position" fullWidth />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default CustomEditPage;
