import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
  CustomRoutes
} from "react-admin";
import { Layout } from "./Layout";
import { dataProvider } from "./dataProvider";
import { authProvider } from "./authProvider";
import AlertEdit from './alert/AlertEdit'; // Import your alert page
import AlertCreate from './alert/AlertCreate'; // Import your custom Create component
import InventoryEdit from './inventory/InventoryEdit'; // Import your inventory page
import InventoryCreate from './inventory/InventoryCreate'; // Import your inventory page
import InventoryShow from './inventory/InventoryShow'; // Import your inventory page
import CustomTabbedFormPage from './CustomTabbedFormPage'; // Import your custom page component
import CustomFormPage from './CustomFormPage'; // Import your custom page component

import CustomMenu from './CustomMenu'; // Import your custom menu
import { Route } from 'react-router-dom'; // Import from react-router-dom
import CustomLayout from './CustomLayout'; // Import your custom layout



import { Auth0Client } from "@auth0/auth0-spa-js";
import { Auth0AuthProvider } from "./auth0/authProvider";




console.log("!!!!!!!!HERE!!!!!!!!!!!!");
/*
const COGauthProvider = CognitoAuthProvider({
  mode: 'oauth',
  clientId: '6a7hj4ha09993ukru5s1obivjh',
  userPoolId: 'eu-west-2_CUMeXKbZi',
  hostedUIUrl: 'https://inventageflow.auth.eu-west-2.amazoncognito.com',
  scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
  oauthGrantType: 'code',
  redirect_uri:'http://localhost:5173/auth-callback'
},
  { applicationName: 'InventageFlowAdmin' }
);
*/

const auth0 = new Auth0Client({
  domain: 'dev-zdajgwtcemejbrb3.uk.auth0.com',
  clientId: 'W4rvFKABJk56HWkGT3yJqXDY9D9r85eh',
  cacheLocation: 'localstorage',
  authorizationParams: {
      audience: 'https://dev-zdajgwtcemejbrb3.uk.auth0.com/api/v2/',
      scope: 'openid profile email',
  },
});

console.log("22222!!!!!!!!HERE!!!!!!!!!!!!" + JSON.stringify(auth0));


/*
VITE_AUTH0_DOMAIN="dev-zdajgwtcemejbrb3.uk.auth0.com"
VITE_AUTH0_CLIENT_ID="W4rvFKABJk56HWkGT3yJqXDY9D9r85eh"
VITE_AUTH0_AUDIENCE="https://dev-zdajgwtcemejbrb3.uk.auth0.com/api/v2/"
VITE_LOGIN_REDIRECT_URL="http://localhost:5173/auth-callback"
VITE_LOGOUT_REDIRECT_URL="http://localhost:5173"
*/

const auth0authProvider = Auth0AuthProvider(auth0, {
  loginRedirectUri: 'http://localhost:5173/auth-callback',
});

console.log("33333!!!!!!!!HERE!!!!!!!!!!!!" + JSON.stringify(auth0authProvider));




/*
if (typeof globalThis.global === 'undefined') {
  globalThis.global = globalThis;
}

// Check if `global` is undefined and polyfill it
if (typeof global === 'undefined') {
  window.global = window;
}


  */

/*
const userPool = new CognitoUserPool({
  UserPoolId: 'eu-west-2_CUMeXKbZi',
  ClientId: '6a7hj4ha09993ukru5s1obivjh',
});
*/

//const localauthProvider = CognitoAuthProvider(userPool);






export const App = () => (
  <Admin
    layout={CustomLayout}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="inventories"
      list={ListGuesser}
      edit={InventoryEdit}
      show={InventoryShow}
      create={InventoryCreate}
    />

    <Resource
      name="alerts"
      list={ListGuesser}
      edit={AlertEdit}
      show={ShowGuesser}
      create={AlertCreate}
    />


    {/*<Resource name="alerts" create={CustomFormPage} />*/}

    {/* Add custom routes */}
    <CustomRoutes>
      <Route path="/custom-form" element={<CustomFormPage />} />
      <Route path="/alert-form/:id" element={<AlertEdit />} />
    </CustomRoutes>

  </Admin>
);

