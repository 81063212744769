import { Create, SimpleForm, TextInput, NumberInput, SelectInput, CreateProps, DateField, NumberField, SimpleShowLayout, TextField, Show } from 'react-admin';
import { JSX } from 'react/jsx-runtime';
import { Box } from '@mui/material'; // Using Material UI for styling
import SectionTitle from '../layout/component/sectionTitle';

const InventoryShow = (props: JSX.IntrinsicAttributes & CreateProps<any, Error, any>) => (
  <Show {...props}>
    <SimpleShowLayout>
      <SectionTitle title="Inventory" />
      <Box display="flex" flexDirection="row" gap="1rem" mb="1rem">
        <Box flex={1}>
          <TextField source="inventory.org.id" label="Organisation" />
        </Box>
        <Box flex={1}>
          <TextField source="inventory.product.id" label="Product" />
        </Box>
        <Box flex={1}>
          <TextField source="inventory.source.store" label="Store" />
        </Box>
        <Box flex={1}>
          <NumberField source="inventory.inventory.position" label="Stock Position" />
        </Box>
      </Box>
    </SimpleShowLayout>
  </Show>
);

export default InventoryShow;