interface SectionTitleProps {
  title: string; // Define the type for the title prop
}

// Custom component for section title
export const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => (
  <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: '0.5rem', marginBottom: '1rem' }}>
      {title}
  </h3>
);

export default SectionTitle;