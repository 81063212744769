import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, NumberInput, useDataProvider, EditProps } from 'react-admin';
import { Box, Button, MenuItem, Select, FormControl, InputLabel, TextField, Grid } from '@mui/material'; // Using Material UI for styling
import { JSX } from 'react/jsx-runtime';


import SectionTitle from '../layout/component/sectionTitle'; // Import your custom Create component


// Define the type for the options
interface Option {
    id: string | number;
    name: string;
}


// Custom Edit Page
const CustomEditPage = (props: JSX.IntrinsicAttributes & EditProps<any, Error>) => {
    const dataProvider = useDataProvider();

    // Example of fetching values from a data provider
    // Replace 'resourceName' with the actual resource name
    const [dataProviderOptions, setDataProviderOptions] = React.useState<Option[]>([]);

    React.useEffect(() => {
        dataProvider.getList('notifiers', {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'name', order: 'ASC' }
        }).then(response => {
            setDataProviderOptions(response.data.map(item => ({ id: item.id, name: item.name })));
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [dataProvider]);

    // Static data for dropdowns
    const staticDropdownOptions = [
        { id: '1', name: 'Product' },
        { id: '2', name: 'Category' },
        { id: '3', name: 'Catalog' }
    ];

    // Static data for dropdowns
    const staticFrequencyTypeOptions = [
        { id: 'daily', name: 'Daily' },
        { id: 'weekly', name: 'Weekly' }
    ];

    const staticFrequencyFrequencyOptions = [
        { id: 'once', name: 'Once' },
        { id: 'twice', name: 'Twice' }
    ];

    return (
        <Edit resource="alerts" {...props}>
            <SimpleForm>


                <SectionTitle title="Item or Group" />
                <Box display="flex" flexDirection="row" gap="1rem" mb="1rem">
                    <Box flex={1}>
                        <SelectInput source="item.type" choices={staticDropdownOptions} label="Group Type" fullWidth />
                    </Box>
                    <Box flex={1}>
                        <TextInput source="item.id" label="Group Item Identifier" fullWidth />
                    </Box>
                    <Box flex={1}>
                        <NumberInput source="item.threshold" label="Threshold" fullWidth />
                    </Box>
                </Box>


                {/* Section 2: Notification */}
                <SectionTitle title="Notification" />
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap="1rem" mb="1rem">
                    <Box flex={1}>
                        <SelectInput source="notification.type" label="Notification Type" choices={dataProviderOptions} fullWidth />
                    </Box>
                    {Array.from({ length: 8 }, (_, index) => (
                        <Box key={index} flex={1}>
                            <TextInput source={`notification.recipients[${index}].recipient`} label={`Recipient ${index + 1}`} fullWidth />
                        </Box>
                    ))}
                </Box>

                {/*
                <SectionTitle title="Notification" />
                <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    <SelectInput source="dataProviderDropdown" choices={dataProviderOptions} label="Data Provider Dropdown" />
                    {Array.from({ length: 8 }, (_, index) => (
                        <TextInput key={index} source={`notificationField${index + 1}`} label={`Notification ${index + 1}`} />
                    ))}
                </div>
                */}
                

                {/* Section 3: Schedule */}
                <SectionTitle title="Schedule" />
                <Box display="flex" flexDirection="row" gap="1rem">
                    <Box flex={1}>
                        <SelectInput source="frequency.type" label="Frequency Type" choices={staticFrequencyTypeOptions} fullWidth />
                    </Box>
                    <Box flex={1}>
                        <SelectInput source="frequency.frequency" label="Frequency" choices={staticFrequencyFrequencyOptions} fullWidth />
                    </Box>
                </Box>

            </SimpleForm>
        </Edit>
    );
};

export default CustomEditPage;
