// CustomMenu.tsx
import React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import PeopleIcon from '@mui/icons-material/People';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CustomIcon from '@mui/icons-material/Build';

import Submenu from './layout/component/subMenu'; // Import your custom Create component



const CustomMenu: React.FC = (props) => (
    <Menu {...props}>
        {/* Existing menu items */}
        <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />} />
        <MenuItemLink to="/inventories" primaryText="Inventories" leftIcon={<PostAddIcon />} />
        <MenuItemLink to="/alerts" primaryText="Alerts" leftIcon={<PostAddIcon />} />
        <Submenu text="Administration" icon={<PostAddIcon />}>
            <MenuItemLink to="/alertTypes" primaryText="Alert Types" leftIcon={<PostAddIcon />} />
            <MenuItemLink to="/groupTypes" primaryText="Group Types" leftIcon={<PostAddIcon />} />
            <MenuItemLink to="/frequencyTypes" primaryText="Frequency Types" leftIcon={<PostAddIcon />} />
            <MenuItemLink to="/frequencies" primaryText="Frequencies" leftIcon={<PostAddIcon />} />
        </Submenu>
  </Menu>


);

export default CustomMenu;