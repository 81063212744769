// CustomLayout.tsx
import React, { ReactNode } from 'react';
import { Layout } from 'react-admin';
import CustomMenu from './CustomMenu';

const CustomLayout: React.FC<{ children: ReactNode }> = (props) => (
    <Layout {...props} menu={CustomMenu} />
);

export default CustomLayout;
